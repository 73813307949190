import React from 'react'
import { Alert, Grid, useClientOptions } from '@ui/paintscout'
import { makeStyles } from '@material-ui/core'
import { useQuote } from '../context'
import { getQuoteAlerts, getObjectLabels } from '@paintscout/util/builder'

const useStyles = makeStyles((theme) => ({
  alert: {
    maxWidth: 'min(954px, 100%)',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'min(718px, 100%)'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '96%'
    }
  }
}))

export interface CustomerViewQuoteAlertsProps {
  revision: boolean
}

export default function CustomerViewQuoteAlerts(props: CustomerViewQuoteAlertsProps) {
  const { revision } = props
  const classes = useStyles()
  const { quote } = useQuote()
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options, quote, invoice: quote.is_invoice })
  const quoteAlerts = getQuoteAlerts({
    quote,
    options,
    isCustomerView: true
  })

  if (revision)
    quoteAlerts.unshift({
      severity: 'info',
      content: `This is a historical version of the ${objectLabels.quote.value}.`
    })

  if (quoteAlerts.length === 0) return null

  return (
    <Grid container spacing={0} justifyContent="center">
      {quoteAlerts.map((alert, i) => (
        <Grid key={i} item xs={12} className={classes.alert}>
          <Alert
            severity={alert.severity}
            variant={'default'}
            content={alert.content}
            title={alert.title}
            tooltip={alert.tooltip}
            centerIcon={alert.key === 'serviceTitan'}
          />
        </Grid>
      ))}
    </Grid>
  )
}
