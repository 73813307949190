import type { OptionsDocument, QuoteDocument, QuoteStatus } from 'paintscout'
import React from 'react'
import type { QuoteAlert } from '../'
import { getFeature } from '../../../options'
import { getObjectLabels } from '../../../util/get-object-labels'
import { showHiddenWarning } from './show-hidden-warning'

export interface GetQuoteAlertsProps {
  quote: QuoteDocument
  options: OptionsDocument
  isCustomerView?: boolean
}
export function getQuoteAlerts({ quote, options, isCustomerView }: GetQuoteAlertsProps): QuoteAlert[] {
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })
  const hasSmartTemplate = getFeature({ options, path: 'quotes.smartTemplate' })
  const alerts = [] as QuoteAlert[]

  if (isCustomerView) {
    if (quote.paymentError) {
      alerts.push({
        severity: 'warning',
        content: 'Hang tight! Your payment is pending. Please check back later.'
      })
    }
  } else {
    if (quote.paymentError) {
      alerts.push({
        severity: 'error',
        content: React.createElement(
          React.Fragment,
          {},
          `A payment error occurred, and customer payments have been temporarily disabled on this ${objectLabels.quote.value.toLowerCase()}. The payment may have still gone through — please `,
          React.createElement(
            'a',
            { href: 'mailto:support@paintscout.com', style: { color: 'inherit' } },
            'contact our support team'
          ),
          ' to confirm and resolve it.'
        )
      })
    }

    if (quote.sendError) {
      alerts.push({
        severity: 'error',
        content: quote.errorMessage ? quote.errorMessage : `An error occured sending this ${objectLabels.quote.value}.`,
        tooltip: 'Clear this error by sending it again.'
      })
    } else if (quote.archived) {
      alerts.push({
        severity: 'warning',
        content: `This ${objectLabels.quote.value} is Archived.`
      })
    }

    if (quote.clientVisible === false) {
      alerts.push({
        severity: 'warning',
        content: `This ${objectLabels.quote.value} is currently hidden from customers. Activate it through the sidebar "Advanced" tab.`
      })
    }
    if (quote.crewVisible === false) {
      alerts.push({
        severity: 'warning',
        content: `This ${objectLabels.workOrder.value} is hidden from crews. Activate it through the sidebar "Advanced" tab.`
      })
    }

    if (showHiddenWarning(quote, options)) {
      alerts.push({
        severity: 'warning',
        content: `This ${objectLabels.quote.value.toLowerCase()} has hidden items that may affect the listed prices correctly adding to the total price. To access and modify these hidden areas, please click the ${
          objectLabels.workOrder.value
        } tab.`
      })
    }

    // no quote alerts for status in SB
    if (quote.status?.note?.value) {
      alerts.push({
        severity: getStatusNoteSeverity(quote?.status?.value) as QuoteAlert['severity'],
        content: quote.status.note.value
      })
    }

    if (quote.type === 'quoteTemplate' && hasSmartTemplate) {
      const legacyTemplates = options.options?.quoteTemplates?.order.filter(
        (templateId) => !options.options.quoteTemplates?.values[templateId]?.smartTemplate
      )

      if (legacyTemplates.length > 0) {
        if (quote?.smartTemplate) {
          alerts.push({
            severity: 'info',
            content: React.createElement(
              React.Fragment,
              {},
              `This is a Smart Template: the production rates, products, hourly rate, and terms are not customizable and will automatically use the latest version from your settings and the selected `,
              objectLabels.quote.value.toLowerCase(),
              ' type.',
              React.createElement(
                'div',
                {},
                React.createElement(
                  'a',
                  {
                    href: 'http://help.paintscout.com/en/articles/8718786-smart-templates',
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  },
                  'Learn More'
                )
              )
            ) as any as string
          })
        } else {
          alerts.push({
            severity: 'info',
            content: React.createElement(
              React.Fragment,
              {},
              `Everything on this template is fully customizable but will `,
              React.createElement('strong', {}, 'not'),
              ` automatically update with the latest production rates, products, or hourly rate from your settings. To work with the latest version of information from your settings and selected ${objectLabels.quote.value.toLowerCase()} type, we recommed creating a new ${objectLabels.quote.value.toLowerCase()} template, which will utilize our `,
              React.createElement(
                'a',
                {
                  href: 'http://help.paintscout.com/en/articles/8718786-smart-templates',
                  target: '_blank',
                  rel: 'noopener noreferrer'
                },
                'Smart Template'
              ),
              ` functionality.`
            ) as any as string
          })
        }
      } else {
        alerts.push({
          severity: 'info',
          content: `This is an ${objectLabels.quote.value.toLowerCase()} template: the production rates, products, hourly rate, and terms are not customizable and will automatically use the latest version from your settings and the selected ${objectLabels.quote.value.toLowerCase()} type.`
        })
      }
    }
  }

  function getStatusNoteSeverity(status: QuoteStatus) {
    switch (status) {
      case 'accepted':
        return 'success'
      case 'declined':
        return 'error'
      case 'onHold':
        return 'warning'
      default:
        return 'default'
    }
  }

  return alerts
}
