import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { hasHiddenItems } from '../../util/has-hidden-items'
import { getQuoteOptions } from '../../util'
import { hasHiddenArea } from '../../util/has-hidden-area'

export const showHiddenWarning = (quote: QuoteDocument, options: OptionsDocument): boolean => {
  const { showPrices, stackSubstrates, showDetailedBreakdown } = getQuoteOptions({ quote, options })
  const hiddenItems = hasHiddenItems(quote)
  const hiddenArea = hasHiddenArea(quote)

  // Area view different than substrate view
  if (quote.defaultView === 'substrate') {
    if (showPrices && hiddenItems) {
      return true
    } else {
      // if no prices in substrate view, no warning
      return false
    }
  } else {
    if (showPrices && (((stackSubstrates || showDetailedBreakdown) && hiddenItems) || (hiddenItems && hiddenArea))) {
      return true
    } else {
      // If no prices or showing a grouped area price, no warning
      return false
    }
  }
}
