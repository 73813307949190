import React, { useState } from 'react'
import type { DialogProps } from '@ui/paintscout'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogAfterContent,
  Button,
  InputField,
  HtmlContent,
  getPresentationContentStyles
} from '@ui/paintscout'

import type { StyleClasses } from '@ui/core/theme'
import type { Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { getObjectLabels } from '@paintscout/util/builder'
import type { OptionsDocument, QuoteDocument } from 'paintscout'

export interface DeclineQuoteDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  options: OptionsDocument
  quote: QuoteDocument
  onConfirm?: (event: React.MouseEvent<HTMLElement>, reason: string) => void
  onCancel?: (event: React.MouseEvent<HTMLElement>) => void
}

export interface DeclineQuoteDialogState {
  note?: string
  error?: string
}

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    htmlContent: {
      ...getPresentationContentStyles(theme)
    },
    disclaimer: {
      flexGrow: 0
    }
  })
}

function DeclineQuoteDialog({ classes, onCancel, onConfirm, options, ...otherProps }: DeclineQuoteDialogProps) {
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)

  const { declineDisclaimer } = options.options
  const objectLabels = getObjectLabels({ options })
  const title = `Decline ${objectLabels.quote.value}`

  const leftButton = (
    <Button disabled={loading} onClick={onCancel} variant={'text'}>
      Cancel
    </Button>
  )

  return (
    <Dialog {...otherProps} className={classes.root}>
      <DialogTitle loading={loading}>{title}</DialogTitle>
      <DialogContent>
        <InputField
          label={`Reason for Declining this ${objectLabels.quote.value.toLowerCase()}`}
          value={note}
          onChange={(ev) => {
            setNote(ev.target.value)
          }}
          disabled={loading}
          multiline={true}
          fullWidth={true}
        />
      </DialogContent>
      {declineDisclaimer && (
        <DialogAfterContent classes={{ root: classes.disclaimer }}>
          <HtmlContent content={declineDisclaimer} className={classes.htmlContent} />
        </DialogAfterContent>
      )}
      <DialogActions leftButton={leftButton}>
        <Button loading={loading} onClick={handleConfirm} variant={'contained'}>
          {title}
        </Button>
      </DialogActions>
    </Dialog>
  )

  async function handleConfirm(event: any) {
    if (onConfirm) {
      setLoading(true)
      await onConfirm(event, note)
      setLoading(false)
    }
  }
}

export default withStyles(styles)(DeclineQuoteDialog)
