import type { QuoteDocument } from 'paintscout'
import { getItemSection } from '../get-item-section'

export const hasHiddenArea = (quote: QuoteDocument): boolean => {
  for (const areaKey in quote.areas) {
    const area = quote.areas[areaKey]
    const substrates = Object.values(area.substrates)

    const allHidden = substrates.every((substrate) => {
      return Object.values(substrate).every((item) => {
        const section = getItemSection(item)
        return item.workOrderOnly === true && !['options', 'archived'].includes(section)
      })
    })

    if (allHidden) {
      return true
    }
  }

  return false
}
