import type { QuoteDocument } from 'paintscout'
import { getItemSection } from '../get-item-section'

export const hasHiddenItems = (quote: QuoteDocument): boolean => {
  // Check each area's substrates>substrateItems until one with workOrderOnly
  // that is not in options section appears.
  const res = Object.keys(quote?.areas ?? {}).reduce((substrateHidden, currentAreaKey) => {
    // If no hidden substrates yet, keep looking
    if (!substrateHidden) {
      const substratesHidden = Object.keys(quote.areas[currentAreaKey].substrates).reduce(
        (substrateItemHidden, currentSubstrateItemKey) => {
          // If no hidden substrate item yet, keep looking
          if (!substrateItemHidden) {
            const hiddenSubstrateItem = quote.areas[currentAreaKey].substrates[currentSubstrateItemKey].filter(
              (substrate) => {
                // If workOrderOnly and not in options or archived, we have a hidden one that can affect price
                return substrate.workOrderOnly === true && !['options', 'archived'].includes(getItemSection(substrate))
              }
            ).length
            if (hiddenSubstrateItem) {
              substrateItemHidden = true
            }
            return substrateItemHidden
          } else {
            return substrateItemHidden
          }
        },
        false
      )
      if (substratesHidden) {
        substrateHidden = true
      }
      return substrateHidden
    } else {
      return substrateHidden
    }
  }, false)
  return res
}
